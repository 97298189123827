<script>
export default {
  name: "PriceView.vue",

  props: {
    rate: {
      type: Object,
      require: true,
      default: () => {
      }
    },

    showLoader: {
      showLoader: Boolean,
      require: false,
      default: false
    }
  },

  data: () => ({
    addservicetotal: 0,
  }),

  watch: {
    rate: {
      handler() {
        this.getaddservicetotal()
      },
      deep: true
    },
  },

  mounted() {
    this.getaddservicetotal()
  },

  methods: {
    getnumber(value) {
      return parseFloat(value).toLocaleString('ru-RU')
    },

    getaddservicetotal() {
      this.addservicetotal = 0;
      for (let i = 0; i < this.rate.addservices.length; i++) {
        this.addservicetotal = this.addservicetotal + this.rate.addservices[i].cost
      }
    },
  }
}
</script>

<template>
  <div class="price-main-container">
    <div class="price-container">
      <div class="price-group-title">
        Основные услуги
      </div>
      <div class="price-group-items">

        <table class="rate-card-detail-table" v-if="rate">
          <thead>
          <tr style="font-weight: bold; text-align: left">
            <td class="td-left">Услуга</td>
            <td class="td-right">Курс</td>
            <td class="td-right">Стоимость, вал</td>
            <td class="td-right">Стоимость,&nbsp;&#165;</td>
            <td class="td-right">Стоимость,&nbsp;&#8381;</td>
          </tr>
          </thead>

          <tr
            class="rate-card-detail-service"
            v-for="service in rate.rate.detail"
            :key="service.id"
          >
            <td class="td-left">{{ service.service }}</td>
            <td class="td-right">{{ service.cbr_rate }}</td>
            <td class="td-right">{{ getnumber(service.summa) }}&nbsp;
              <span v-if="service.cur_code == 'USD'">&#36;</span>
              <span v-else>&#8381;</span>
            </td>
            <td class="td-right">{{ getnumber(service.rmb_price) }}&nbsp;
              <span v-if="service.cur_code == 'USD'">&#165;</span>
              <span v-else>&#165;</span>
            </td>
            <td class="td-right">{{ getnumber(service.cost) }} &#8381;</td>
          </tr>

          <tr class="tr-total">
            <td colspan="4" class="td-right">Итого:</td>
            <td class="td-right">{{ getnumber(rate.rate.total) }}&nbsp;&#8381;</td>
          </tr>
        </table>
      </div>

      <div class="price-group-title">
        Дополнительные услуги
      </div>

      <div
        v-if="rate.addservices.length > 0"
        class="price-group-items"
      >
        <div
          class="text-center"
          v-if="showLoader === true"
        >
          <v-progress-circular
            :size="50"
            color="var(--main-orange)"
            indeterminate
          ></v-progress-circular>
        </div>

        <table class="rate-card-detail-table">
          <thead>
          <tr style="font-weight: bold; text-align: left">
            <td class="td-left">Услуга</td>
            <td class="td-right">Стоимость, &#8381;</td>
          </tr>
          </thead>

          <tr
            class="rate-card-detail-service"
            v-for="service in rate.addservices"
            :key="service.id"
          >
            <td class="td-left">{{ service.service }}</td>
            <td class="td-right">{{ getnumber(service.cost) }} &#8381;</td>
          </tr>

          <tr class="tr-total">
            <td colspan="0" class="td-right">Итого:</td>
            <td class="td-right">{{ getnumber(addservicetotal) }}&nbsp;&#8381;</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="price-results">
      Полная стоимость заявки:
      {{ addservicetotal > 0 ? getnumber(rate.rate.total + addservicetotal) : getnumber(rate.rate.total) }}&nbsp;&#8381;
    </div>
  </div>
</template>

<style scoped>
.price-main-container {
  padding: 20px 10px;
  border-radius: 10px;
}

.price-group-title {
  width: 98%;
  text-align: left;
  border-bottom: #6c8693 1px solid;
  margin: 1.61%;
  padding: 0 0 4px;
}

.price-group-items {
  padding-bottom: 3.22%;
}

.price-results {
  width: 98%;
  text-align: right;
  font-weight: bold;
  padding-top: 1.61%;
}

.rate-card-detail-service {
  line-height: 25px;
}

.rate-card-detail-table {
  width: 98%;
  margin: auto;
  font-size: 9pt;
  padding-top: 1.61%;
}

.td-right {
  text-align: right;
  padding-right: 1px;
}

.td-left {
  text-align: left;
  padding-right: 1px;
}

.tr-total {
  background-color: #fff;
  font-weight: bold;
  font-size: 11pt;
}
</style>
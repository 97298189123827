<script>
import {order_request_form_new} from "../../jsons/order-request-form-new";
import CVTBTextInput from "../../common/CVTBTextInput.vue";
import CVTBMemo from "../../common/CVTBMemoInput.vue";
import CVTBCheckboxInput from "../../common/CVTBCheckboxInput.vue";

export default {
  name: 'MainInfoForm',

  props: {
    rate: {
      type: [Array, Object],
      required: true,
    },

    security_price: {
      type: Number
    },

    schedule: {
      type:  [Array, Object],
      required: true,
    },

    agent_data: {
      type:  [Array, Object],
      required: true,
    },
  },

  components: {
    CVTBCheckboxInput,
    CVTBMemo,
    CVTBTextInput,
  },

  data() {
    return {
      args: {},
      elements: order_request_form_new.main_info,
      hide_line_name : 'Vedexx',

      is_show_cont_cnt: false,

      formdata: {
        place_from_name: "",
        cont_type: "",
        place_to_name: "",
        station_name: "",
        line_name: "",
        port_name: "",
        across_place_name_ext: "",
        exp_name: "",
        drop_place: "",
        client_id: null,
        rate: null,
        order_num: "",
        agent_id: null,
        agent_name: null,

        is_vtt: false,
        is_hbl: true,
        is_party: false,
        is_broker: false,
        is_car: false,
        is_exw: false,
        is_protected: false,
        is_inshurance: false,
        cont_cnt: null,
        special_condition: "",
      }
    }
  },

  computed: {
    findRailway() {
      return this.args.rate.detail.some(service => service.type_id === 214)
    },
  },

  mounted() {
    this.fillFormdata()
    if (this.args.rate.is_not_hbl !== 1) {
      this.$emit('checkService', { element: 'is_hbl', value: true })
    }
  },

  methods: {
    fillFormdata() {
      this.args = this.rate
      this.formdata.place_from_name = this.args.rate.base_place_from_name
      this.formdata.cont_type = this.args.rate.unit_code
      this.formdata.place_to_name = this.args.rate.base_place_to_name
      this.formdata.station_name = this.args.rate.station_name
      this.formdata.line_name = this.args.rate.line_name
      this.formdata.port_name = this.args.rate.place_across_name
      this.formdata.across_place_name_ext = this.args.rate.across_place_name_ext
      this.formdata.exp_name = this.args.rate.charterer_name
      this.formdata.drop_place = this.args.rate.base_place_to_name

      if (this.args.rate.is_not_hbl === 1) {
        this.formdata.is_hbl = false
      }
    },

    validate() {
      return this.$refs.mainInfoForm.validate()
    },

    OnEditChange(data) {
      this.formdata[data.element] = data.value
      this.$emit('updateField', { [data.element]: data.value });
    },

    fillAgent(data) {
      this.formdata.agent_name = data.agent_name
      this.formdata.agent_id = data.agent_id
    },

    OnCheckBoxChange(data){
      if (data.element == 'is_party') {
        this.is_show_cont_cnt = data.value
      }
    },

    checkService(element) {
      this.$emit('checkService', element)
      if (element.element == 'is_party') {
        this.is_show_cont_cnt = element.value
      }
      if (element.element == 'is_not_vtt') {
        this.$emit('getAutoServiceInfo')
      }
    },

    checkIsExw(element) {
      this.$emit('checkIsExw', element)
    }
  },
}
</script>

<template>
  <v-form ref="mainInfoForm">
    <div class="cv-grid-div mt-4">
      <CVTBTextInput
        @input="formdata.place_from_name = $event"
        @change="OnEditChange"
        :element="elements.place_from_name"
        :val="formdata.place_from_name"
      />

      <CVTBTextInput
        @input="formdata.cont_type = $event"
        :element="elements.cont_type"
        @change="OnEditChange"
        :val="formdata.cont_type"
      />

      <CVTBTextInput
        @input="formdata.place_to_name = $event"
        @change="OnEditChange"
        :element="elements.place_to_name"
        :val="formdata.place_to_name + ' (' + formdata.station_name + ')'"
      />

      <CVTBTextInput
        @input="(args.rate.is_hide_line == 0 || formdata.client_id == -1) ? formdata.line_name = $event : ''"
        @change="OnEditChange"
        :element="elements.line_name"
        :val="(args.rate.is_hide_line == 0 || formdata.client_id == -1) ? formdata.line_name : hide_line_name"
      />

      <CVTBTextInput
        @input="formdata.port_name = $event"
        :element="elements.port_name"
        @change="OnEditChange"
        :val="formdata.port_name + ' (' + formdata.across_place_name_ext + ')'"
      />

      <CVTBTextInput
        @input="formdata.exp_name = $event"
        @change="OnEditChange"
        :element="elements.exp_name"
        :val="formdata.exp_name"
      />

      <CVTBTextInput
        @input="formdata.drop_place = $event"
        @change="OnEditChange"
        :element="elements.drop_place"
        :val="formdata.drop_place"
      />

      <CVTBTextInput
        @input="formdata.order_num = $event"
        @change="OnEditChange"
        :element="elements.order_num"
        :val="formdata.order_num"
      />

      <v-autocomplete
        class="cv-row-div"
        v-if="schedule.length > 0"
        v-model="formdata.schedule"
        :items="schedule"
        label="Судно"
        return-object
        :item-text="item => item.vassel_name + ', '
          + new Date(item.sea_start_date).toLocaleDateString('ru', {
            month: 'long',
            day: 'numeric'
          })"
        menu-props="auto"
        outlined
        clearable
        item-color="var(--main-orange)"
        color="var(--main-orange)"
      >
        <template v-slot:item="data" class="rate-panel__input-content">
          <template class="rate-panel__input-content">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.vassel_name }},
                <b>{{
                    new Date(data.item.sea_start_date).toLocaleDateString('ru', {
                      month: 'long',
                      day: 'numeric'
                    })
                  }}</b>

              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>

      <v-autocomplete
        class="cv-row-div"
        v-if="agent_data.length > 0 && args.rate.is_agent != 1"
        v-model="formdata.agent_name"
        :items="agent_data"
        :label="elements.agent_name.caption"
        item-text="agent_name"
        return-object
        menu-props="auto"
        outlined
        clearable
        item-color="var(--main-orange)"
        color="var(--main-orange)"
        @change="fillAgent"
      >
        <template v-slot:item="data" class="">
          <template>
            <v-list-item-content>
              <v-list-item-title class="cv-row-div pb-1">
                <span class="mt-1">{{ data.item.agent_name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>

    <div class="cv-row-div cv-row-margin mt-0">
      <CVTBCheckboxInput
        :disabled="args.rate.is_not_vtt == 1 ? true : false"
        :hint="args.rate.is_not_vtt == 1 ? 'Сервис недоступен' : ''"
        :element="elements.is_vtt"
        @selected="OnEditChange($event), checkService($event)"
        :val="formdata.is_vtt"
      />

      <CVTBCheckboxInput
        :disabled="args.rate.is_not_hbl == 1 ? true : false"
        :hint="args.rate.is_not_hbl == 1 ? 'Сервис недоступен' : ''"
        :element="elements.is_hbl"
        @selected="OnEditChange($event), checkService($event)"
        :val="formdata.is_hbl"
      />

      <CVTBCheckboxInput
        :element="elements.is_inshurance"
        @selected="OnEditChange($event)"
        :val="formdata.is_inshurance"
      />

      <CVTBCheckboxInput
        :element="elements.is_broker"
        @selected="OnEditChange($event), checkService($event)"
        :val="formdata.is_broker"
      />

      <CVTBCheckboxInput
        :element="elements.is_exw"
        @selected="OnEditChange, checkIsExw($event)"
        :val="formdata.is_exw"
      />
    </div>

    <div class="cv-row-div mt-2">
      <CVTBCheckboxInput
        :element="elements.is_party"
        @selected="OnEditChange($event), checkService($event)"
        :val="formdata.is_party"
      />

      <CVTBTextInput
        class="pt-5"
        v-if="is_show_cont_cnt"
        @input="formdata.cont_cnt = $event"
        :element="elements.cont_cnt"
        :val="formdata.cont_cnt"
        @change="OnEditChange"
      />
    </div>

    <v-alert
      v-if="Number(args.rate.is_vtt_price) !== 0 && formdata.is_vtt"
      dense
      class="request-form__alert mt-2"
      type="info"
      color="var(--main-orange)"
    >
      <span>Стоимость оформления ВТТ:
         <strong> {{ args.rate.is_vtt_price }} &#8381;/1 транспортная декларация</strong>
      </span>
    </v-alert>

    <v-alert
      v-if="findRailway"
      dense
      class="request-form__alert mb-6 mt-2"
      type="info"
      color="var(--main-orange)"
    >
      <span>*Если ваш груз по коду ТНВЭД подлежит охране по жд, то к ставке будет добавлен соответствующий тариф
        <strong v-if="Number(security_price) !== 0">стоимостью {{ security_price }} &#8381;</strong>
        <strong v-else>. Точная стоимость охраны требует уточнения с подрядчиком</strong>
      </span>
    </v-alert>

    <div>
      <CVTBMemo
        class="mt-6"
        :element="elements.special_condition"
        @change="OnEditChange"
        :val="formdata.special_condition"
      />
    </div>
  </v-form>
</template>

<style lang="scss" scoped>

.cv-grid-div {
  display: grid;
  z-index: 400;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  align-content: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  justify-items: flex-start;

  &:last-child {
    padding-bottom: 235px;
  }

  @media (max-width: 620px) {
    flex-direction: column;
  }
}

.cv-row-div {
  display: flex;
  margin: 0;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.cv-row-margin {
  top: -20px;
  margin: -30px auto -17px;
  justify-content: space-between;

  @media (max-width: 620px) {
    justify-content: start;
  }
}

.rate-panel__circle {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 4px;
  margin: 0 0 0 10px;
  filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.16));
  cursor: pointer;
  font-weight: 700;
  color: white;

  &:hover {
    filter: drop-shadow(1px 3px 4px rgba(0, 0, 0, 0.26));
  }
}

.circle-red {
  background: var(--error-dark);
}

.circle-green {
  background: var(--green-light);
}

.circle-orange {
  background: var(--main-orange);
}

.circle-grey {
  background: var(--main-bg-color);
}

</style>
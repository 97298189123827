<script>
import OrderInvoiceCardList from "./OrderInvoiceCardList";
import CVTBTextInput from "../common/CVTBTextInput";
import CVTBCheckboxInput from "../common/CVTBCheckboxInput.vue";
import CVTBDateInput from "../common/CVTBDateInput";
import FtpUploader from "../common/FtpUploader";
import Modal from "../common/Modal";
import OrderInvoiceAddForm from "./OrderInvoiceAddForm";
import InputAddCard from "../common/InputAddCard";
import PriceView from "./PriceView";
import AlarmGoodsForm from "./AlarmGoodsForm.vue";

import MainInfoForm from "./order-form-tabs/MainInfoForm.vue";
import ConsigneeInfoForm from "./order-form-tabs/ConsigneeInfoForm.vue";

import {order_request_form_spec} from "../jsons/order_request_form";
import { bus } from "../../router/bus";
import md5 from "md5"
export default {
  name: 'OrderRequestForm',
  props:{
    args:{
      type: Object,
      required: true
    },

    autoDeliveryPlace: {
      type: [String, Object],
      required: false
    },

    order: {
      type: [Array, Object],
      required: false,
      default: () => null,
    },

    date: {
      type: [Date, String],
      required: false
    },
  },

  components:{
    CVTBCheckboxInput,
    OrderInvoiceCardList,
    CVTBTextInput,
    CVTBDateInput,
    FtpUploader,
    OrderInvoiceAddForm,
    Modal,
    InputAddCard,
    PriceView,
    AlarmGoodsForm,

    MainInfoForm,
    ConsigneeInfoForm
  },

  data() {
    return {
      isVedexxClient: null,
      showAlarmGoodsForm: false,

      name: order_request_form_spec.name,
      elements: order_request_form_spec,
      valid: false,
      is_show_cont_cnt: false,

      show_add_invc_form: false,

      tabs_checked: {
        main: false,
        recipient: false,
        transmitter: false,
        goods: false,
        exw: true,
      },

      tabs_valid: {
        main: true,
        recipient: true,
        transmitter: true,
        goods: true,
        exw: true,
      },

      tab_valid_error: false,

      profileData: {},
      goodsData: [],
      agentData: [],
      consignesData: [],
      shippersData: [],
      warehousData: [],
      companyInfo: [],
      routerInfo: [],
      deliveryPriceInfo: [],
      insurancePriceInfo: [],

      cityLoadingTo: false,
      cities_to: [],
      searchCityTo: null,
      deliveryAccess: 1,

      addGdsNameInput: false,
      addWhsAddressInput: false,

      consignesDataList: {
        id: "id",
        name: "shortname",
        caption: "Наименование получателя",
        listname: "consignes_list",
        listfield: "shortname",
        rules: [
          v => !!v || 'Наименование обязательно',
          value => {
            const pattern = /[`']/;
            return !pattern.test(value) || "Присутствуют запрещённые символы"
          },
        ]
      },

      is_low_rate: false,
      selected_service: {},
      insurance_service: [],
      security_price: null,
      showServiceLoader: false,

      hide_line_name : 'Vedexx',
      schedule: [],

      formdata: {
        order_key: Math.floor(Math.random() * 1e8),
        place_from_name: "",
        cont_type: "",
        place_to_name: "",
        station_name: "",
        line_name: "",
        port_name: "",
        across_place_name_ext: "",
        exp_name: "",
        drop_place: "",
        client_id: null,
        rate: null,
        order_num: "",
        agent_id: null,
        agent_name: null,

        addservices:[],
        schedule: [],

        fraight_currency_id: null,
        is_vtt: false,
        is_hbl: true,
        is_party: false,
        is_broker: false,
        is_car: false,
        is_exw: false,
        is_protected: false,
        is_inshurance: false,
        is_exchange_differences: false,
        cont_cnt: null,
        special_condition: "",

        consignee_inn: "",
        consignee_name: "",
        consignee_address: "",

        gds_gross_weight: "",
        gds_name: "",
        gds_volume: "",
        ready_date: null,

        warehouse_address: "",
        warehouse_contact: "",
        warehouse_phone: "",
        warehouse_name: "",
        warehouse_recip_name: "",
        delivery_from: "",
        delivery_to: "",

        pickup_address: '',

        files_name: [],

        invoices: [],
      },
    }
  },

  watch: {
    searchCityTo(val) {
      this.cityLoadingTo = true

      this.$postapi(this.$address + this.$getters.getCityList.uri, {
        method: this.$getters.getCityList.name,
        place_name: val
      }).then(data => {
        if (data.error == 0) {
          this.cities_to = JSON.parse(data.message)
          this.cityLoadingTo = false
        }
      });
    },
  },

  computed: {
    isAuth() {
      if (this.$_getsetting('is_auth')) {
        return true
      } else {
        return false
      }
    },

    findRailway() {
      return this.args.rate.detail.some(service => service.type_id === 214)
      // return true
    },
  },

  mounted(){
    this.fillData()
    this.fillOrderDetails()
    this.getUser()
    this.getSecurityServiceInfo()
    this.getAgentData()
    this.getShipScheduleByKey()

    bus.$on("removeInvoice", (args) => {
      this.removeInvoice(args)
    });

    bus.$on("update-invoice", (args) => {
      this.updateInvoice(args)
    });

    bus.$on("updateInvoice", (number) => {
      this.checkInsurance(number)
    });
  },

  methods: {
    OnEditChange(data) {
      this.formdata[data.element] = data.value
    },

    validateTabs() {
      this.tab_valid_error = false
      this.$refs.form.validate()
      if (!this.tabs_checked.goods || !this.tabs_checked.transmitter || !this.tabs_checked.recipient || !this.tabs_checked.exw) {
        this.tab_valid_error = true
      }

      this.tabs_valid.main = this.$refs.mainInfoForm.validate()

      if (this.tabs_checked.recipient) {
        this.tabs_valid.recipient = this.$refs.consigneeInfoForm.validateForm()
      }

      this.tabs_valid.transmitter = this.formdata.invoices.length > 0
      this.tabs_valid.exw = this.formdata.is_exw === false || this.formdata.pickup_address.length > 0
      this.tabs_valid.goods = this.formdata.gds_name.length > 0 && this.formdata.gds_gross_weight && this.formdata.gds_volume
      if (!this.tabs_valid.main || !this.tabs_valid.recipient || !this.tabs_valid.transmitter || !this.tabs_checked.goods || (!this.tabs_valid.exw && this.formdata.is_exw)) {
        this.tab_valid_error = true
      }

      if (!this.tab_valid_error && this.valid) {
        this.validate()
      } else {
        bus.$emit("show_notify", {
          color: "error",
          notifytext: "Пожалуйста, заполните все обязательные разделы формы!"
        });
      }
    },

    validate() {
      if (this.formdata.cont_type === '20\'DV' && this.formdata.gds_gross_weight > 24) {
        bus.$emit("show_notify", {
          color: "error",
          duration: 10000,
          notifytext: "Вес товара не может превышать 24 тонны! Выберите 20DV Heavy!"
        });
      }
      else {
        this.OnSave()
      }
    },

    updateField(updatedData) {
      this.formdata = { ...this.formdata, ...updatedData }
    },

    openLowRatingForm() {
      let index = this.profileData.payers_data.findIndex(el => el.inn === this.formdata.consignee_inn);
      var queryParams = {
        cid: this.profileData.payers_data[index].id
      }
      let routeData = this.$router.resolve({
        path: "/LowRatingForm",
        query: queryParams
      });
      window.open(routeData.href, '_blank');

    },

    fillOrderDetails() {
      if (this.order != null) {
        for (let i = 0; i < this.order.invoices.length; i++) {
          this.formdata.invoices.push(this.order.invoices[i])
        }
        this.formdata.agent_name = this.order.agent_name
        this.formdata.agent_id = this.order.agent_id

        this.formdata.gds_name = this.order.gds_name
        this.formdata.gds_gross_weight = this.order.gds_gross_weight.toString()
        this.formdata.gds_volume = this.order.gds_volume.toString()

        this.formdata.is_car = !!this.order.is_car
        this.formdata.warehouse_name = this.order.warehouse_name
        this.formdata.warehouse_address = this.order.warehouse_address
        this.formdata.warehouse_contact = this.order.warehouse_contact
        this.formdata.warehouse_phone = this.order.warehouse_phone
        this.formdata.warehouse_recip_name = this.order.warehouse_recip_name

        this.tabs_checked.transmitter = true
      }
    },

    getRoute() {
      this.elements.delivery_to.error = false
      this.elements.delivery_to.error_message = ""
      this.$postapi(this.$address + this.$getters.getRoute.uri, {
        method: this.$getters.getRoute.name,
        place_from: this.args.rate.base_place_to_name,
        place_to: this.formdata.delivery_to,
      }).then(data => {
        if (data.error == 0) {
          this.routerInfo = JSON.parse(data.message)
          if (this.routerInfo.kilometers) {
            setTimeout(this.getAutoServiceInfo, 500);
          } else {
            // this.elements.delivery_to.error = true
            // this.elements.delivery_to.error_message = this.routerInfo.from.equalCities
            this.routerInfo.kilometers = 0
            setTimeout(this.getAutoServiceInfo, 500);
            // this.clearDeliveryPrice()
          }
        }
      });
    },

    clearDeliveryPrice() {
      for (let i = 0; i < this.deliveryPriceInfo.length; i++) {
        this.addService(this.deliveryPriceInfo[i], 0)
      }
    },

    checkDeliveryAccess() {
      this.$postapi(this.$address + this.$getters.getSettingByName.uri, {
        method: this.$getters.getSettingByName.name,
        setting_name: "is_use_avtodispetcher",
      }).then(data => {
        if (data.error == 0) {
          this.deliveryAccess = JSON.parse(data.message).is_use_avtodispetcher
        }
      });
    },

    getAutoServiceInfo() {
      this.showServiceLoader = true
      this.clearDeliveryPrice()
      this.$postapi(this.$address + this.$getters.getAutoServiceInfo.uri, {
        method: this.$getters.getAutoServiceInfo.name,
        distance: this.routerInfo.kilometers,
        unit_type: this.args.rate.unit_type,
        weight: this.formdata.gds_gross_weight ? Number(this.formdata.gds_gross_weight) : 0,
        is_vtt: Number(this.formdata.is_vtt),
        is_moscow: this.formdata.place_to_name.includes('Москва') === true ? 1 : 0,
      }).then(data => {
        if (data.error == 0) {
          this.deliveryPriceInfo = JSON.parse(data.message)
          for (let i = 0; i < this.deliveryPriceInfo.length; i++) {
            this.addService(this.deliveryPriceInfo[i], 1)
          }
          this.showServiceLoader = false
        }
      });
    },

    fillUserById(data) {
      this.is_low_rate = false
      if (typeof (data.value) === 'string') {
        this.formdata.consignee_name = data.value
      } else {
        this.formdata.consignee_name = data.value.name
        this.formdata.consignee_inn = data.value.inn
        if (data.value.company_rate <= 6) {
          this.is_low_rate = true
        }
        this.getUserByInn(data.value.inn)
      }
    },

    fillGoodsByID(data) {
      if (typeof (data.value) === 'string') {
        this.formdata.gds_name = data.value
      } else {
        this.formdata.gds_name = data.gds_name
      }
    },

    clearGoods() {
      this.formdata.gds_name = ""
      this.formdata.gds_gross_weight = ""
      this.formdata.gds_volume = ""
    },

    fillWarehouse(data) {
      if (typeof (data) === 'string') {
        this.formdata.warehouse_address = data.value
      } else {
        this.formdata.warehouse_name = data.whs_name
        this.formdata.warehouse_address = data.whs_addr
        this.formdata.warehouse_contact = data.whs_contact
        this.formdata.warehouse_phone = data.whs_phone
        this.formdata.warehouse_recip_name = data.whs_recip_name
      }
    },

    fillAgent(data) {
      this.formdata.agent_name = data.agent_name
      this.formdata.agent_id = data.agent_id
    },

    clearWarehouse() {
      this.formdata.warehouse_name = ''
      this.formdata.warehouse_address = ''
      this.formdata.warehouse_contact = ''
      this.formdata.warehouse_phone = ''
      this.formdata.warehouse_recip_name = ''
    },

    //товары
    getGoodsData() {
      this.$postapi(this.$address + this.$getters.getClientReferenceData.uri, {
        method: this.$getters.getClientReferenceData.name,
        reference_name: 'ref_client_goods',
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0) {
          this.goodsData = JSON.parse(data.message)
        }
      });
    },

    //агенты
    getAgentData() {
      this.$postapi(this.$address + this.$getters.getClientReferenceData.uri, {
        method: this.$getters.getClientReferenceData.name,
        reference_name: 'ref_client_agent',
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0) {
          this.filterAgent(JSON.parse(data.message))
        }
      });
    },

    filterAgent(data) {
      var result = data.filter(item => item.place_from == this.args.rate.base_place_from)
      if (this.findRailway) {
        this.agentData = result.filter(item => item.is_direct == 1)
      } else {
        this.agentData = result.filter(item => item.is_direct == 0)
      }
      if (this.agentData.length > 0) {
        this.formdata.agent_name = this.agentData.find(item => item.is_recomended == 1)
        this.fillAgent(this.formdata.agent_name)
      }
    },

    //отправители
    getShippersData() {
      this.$postapi(this.$address + this.$getters.getClientReferenceData.uri, {
        method: this.$getters.getClientReferenceData.name,
        reference_name: 'ref_client_shippers',
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0) {
          this.shippersData = JSON.parse(data.message)
        }
      });
    },

    //получатели
    getConsignesData() {
      this.$postapi(this.$address + this.$getters.getClientReferenceData.uri, {
        method: this.$getters.getClientReferenceData.name,
        reference_name: 'ref_client_consignes',
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0) {
          this.consignesData = JSON.parse(data.message)
        }
      });
    },

    //cклад
    getWarehouseData() {
      this.checkDeliveryAccess()
      this.$postapi(this.$address + this.$getters.getClientReferenceData.uri, {
        method: this.$getters.getClientReferenceData.name,
        reference_name: 'ref_client_warehouse',
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0) {
          this.warehousData = JSON.parse(data.message)
        }
      });
    },

    getUser() {
      this.$postapi(this.$address + this.$getters.getUserData.uri, {
        method: this.$getters.getUserData.name,
        key: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0) {
          this.profileData = JSON.parse(data.message)
          this.isVedexxClient = this.profileData.is_vedexx_client
          if (this.$_getsetting('client_id') != -1) {
            if (this.profileData.orders_count.all_cnt < 1 && this.profileData.client_data[0].is_refusal === 0 && this.showAlarmGoodsForm === false) {
              this.showAlarmGoodsForm = true
            }
          }
        }
      });
    },

    getUserByInn(inn) {
      this.$getapi(this.$address + this.$getters.getCompanyByInn.uri + inn)
        .then(data => {
          if (data.companyname) {
            this.companyInfo = data
            this.formdata.consignee_address = this.companyInfo.address
          }
        });
    },

    fileUploaded(data) {
      this.formdata.files_name.push(data)
    },

    fillData() {
      this.formdata.rate = this.args.rate
      this.formdata.client_id = this.$_getsetting("client_id")
      this.formdata.ready_date = this.date
      this.formdata.place_from_name = this.args.rate.base_place_from_name
      this.formdata.cont_type = this.args.rate.unit_code
      this.formdata.place_to_name = this.args.rate.base_place_to_name
      this.formdata.station_name = this.args.rate.station_name
      this.formdata.line_name = this.args.rate.line_name
      this.formdata.port_name = this.args.rate.place_across_name
      this.formdata.across_place_name_ext = this.args.rate.across_place_name_ext
      this.formdata.exp_name = this.args.rate.charterer_name
      this.formdata.drop_place = this.args.rate.base_place_to_name

      if (this.autoDeliveryPlace != null) {
        this.cities_to.push(this.autoDeliveryPlace),
          this.formdata.delivery_to = this.autoDeliveryPlace
        this.formdata.is_car = true
        this.getRoute()
      }
    },

    checkIsExw(data) {
      this.formdata[data.element] = data.value
      if (data.value == 1) {
        this.tabs_checked.exw = false
        this.tabs_valid.exw = false
      } else {
        this.tabs_checked.exw = true
        this.tabs_valid.exw = true
      }
    },

    OnCheckBoxChange(data) {
      this.formdata[data.element] = data.value
      if (data.element == 'is_car') {
        if (data.value == 0) {
          this.$refs.form.resetValidation()
          this.elements.delivery_to.error = false
          this.elements.delivery_to.error_message = ''
          this.formdata.delivery_to = ""
          this.clearDeliveryPrice()
        }
      }
      if (data.element == 'is_party')
        this.is_show_cont_cnt = data.value
    },

    getSecurityServiceInfo() {
      if (this.findRailway == true) {
        this.$postapi(this.$address + this.$getters.getSecurityServiceInfo.uri, {
          method: this.$getters.getSecurityServiceInfo.name,
          client_id: this.$_getsetting('client_id'),
          key: this.args.rate.key_orig,
        }).then(data => {
          if (data.error == 0) {
            this.selected_service = JSON.parse(data.message)
            this.security_price = this.selected_service.cost
            this.addService(this.selected_service, 1)
          }
        });
      }
    },

    checkServiceDelay() {
      this.$postapi(this.$address + this.$getters.getServiceDelayInfo.uri, {
        method: this.$getters.getServiceDelayInfo.name,
        service_id: "is_exchange_differences",
        client_id: this.$_getsetting('client_id'),
        rate_key: this.args.rate.key_orig,
      }).then(data => {
        if (data.error == 0) {
          this.selected_service = JSON.parse(data.message)
          this.addService()
        }
      });
    },

    checkService(element) {
      this.showServiceLoader = true
      this.$postapi(this.$address + this.$getters.getServiceInfo.uri, {
        method: this.$getters.getServiceInfo.name,
        service_id: element.element,
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0) {
          this.selected_service = JSON.parse(data.message)
          this.addService(this.selected_service, element.value)
        }
      });
      this.showServiceLoader = false
    },

    changeIncoterms(value) {
      if (value.value.length > 0) {
        this.formdata.rate.delivery_term_id = 4
      } else {
        this.formdata.rate.delivery_term_id = 6
      }
    },

    addInvoice(data) {
      this.formdata.invoices.push(data)
      this.show_add_invc_form = false
    },

    updateInvoice(data) {
      let index = this.formdata.invoices.findIndex(el => md5(el.shipper_name + el.invoice_number) === data.ikey)
      this.formdata.invoices[index].shipper_name = data.invoice.shipper_name
      this.formdata.invoices[index].invoice_number = data.invoice.invoice_number
      this.formdata.invoices[index].shipper_email = data.invoice.shipper_email
      this.formdata.invoices[index].shipper_phone = data.invoice.shipper_phone
      this.formdata.invoices[index].shipper_fio = data.invoice.shipper_fio
      this.formdata.invoices[index].shipper_address = data.invoice.shipper_address
      this.formdata.invoices[index].invoice_volume = data.invoice.invoice_volume
    },

    getShipScheduleByKey() {
      this.$postapi(this.$address + this.$getters.getShipScheduleByKey.uri, {
        method: this.$getters.getShipScheduleByKey.name,
        key: this.args.rate.key_orig,
        on_date: this.args.rate.on_date,
        unit_type: this.args.rate.unit_type
      }).then((data) => {
        if (data.error == 0) {
          this.schedule = JSON.parse(
            data.message
              .replace(/:"f",/gm, `:false,`)
              .replace(/:"t",/gm, `:true,`)
          );
          this.sortSchedule()
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },

    sortSchedule() {
      let filteredArray = this.schedule.filter(obj => this.isDate7DaysAhead(obj.sea_start_date));
      this.schedule = filteredArray
    },

    isDate7DaysAhead(date) {
      let currentDate = new Date();
      let sevenDaysLater = new Date(currentDate);
      sevenDaysLater.setDate(currentDate.getDate() + 7);
      return new Date(date) > sevenDaysLater;
    },

    removeInvoice(data) {
      let index = this.formdata.invoices.findIndex(el => md5(el.shipper_name + el.invoice_number) === data.ikey)
      this.formdata.invoices.splice(index, 1)
    },

    checkInsurance() {
      if (this.formdata.is_inshurance == true && this.formdata.invoices.length > 0) {
        for (let i = 0; i < this.formdata.invoices.length; i++) {
          this.getInshuranceCost(this.formdata.invoices[i].gds_cost, this.formdata.invoices[i].invoice_number)
        }
      } else {
        this.clearInsuranceCost()
      }
    },

    clearInsuranceCost() {
      for (let i = 0; i < this.insurancePriceInfo.length; i++) {
        this.addService(this.insurancePriceInfo[i], 0)
      }
    },

    addInsuranceReference(service) {
      if (this.insurance_service.length < 1) {
        this.insurance_service.push(service)
      }
    },

    getInshuranceCost(gds_cost, number) {
      this.$postapi(this.$address + this.$getters.getInshuranceCost.uri, {
        method: this.$getters.getInshuranceCost.name,
        client_id: this.$_getsetting('client_id'),
        gds_cost: gds_cost,
      }).then(data => {
        if (data.error == 0) {
          this.selected_service = JSON.parse(data.message)
          this.addInsuranceReference(JSON.parse(data.message))
          this.selected_service.service += ', инвойс ' + number
          this.selected_service.service_id = number
          this.insurancePriceInfo.push(this.selected_service)
          this.addService(this.selected_service, 1)
        }
      });
    },

    addService(selected_service, value) {
      if (selected_service.cost != null) {
        if (value == 1) {
          let arr = {
            id: selected_service.service_id,
            cost: selected_service.cost,
            service: selected_service.service,
            cur_code: selected_service.cur_code
          }
          if (!this.formdata.addservices.some(service => service.id === selected_service.service_id)) {
            this.formdata.addservices.push(arr)
          } else {
            let index = this.formdata.addservices.findIndex(el => el.id === selected_service.service_id);
            this.formdata.addservices[index] = arr
          }

        } else {
          const index = this.formdata.addservices.findIndex(n => n.id === selected_service.service_id);
          if (index !== -1) {
            this.formdata.addservices.splice(index, 1);
          }
        }
      }
    },

    countInsuranceReferenceCost() {
      let cost = 0
      for (let i = 0; i < this.insurancePriceInfo.length; i++) {
        cost += this.insurancePriceInfo[i].cost
      }
      this.insurance_service[0].cost = cost
      this.addService(this.insurance_service[0], 1)
      this.clearInsuranceCost()
    },

    OnSave() {
      if (this.$_getsetting('client_id') != null) {
        this.saveFormData();
      } else {
        bus.$emit("show_notify", {
          color: "error",
          notifytext: "Вам необходимо добавить информацию об юр. лице в профиле!"
        });
      }
    },

    saveFormData() {
      let temp_ctc = this.formdata.rate.unit_code
      let temp_ttl = this.formdata.rate.title
      delete this.formdata.cont_type
      this.formdata.rate.title = ""
      this.formdata.rate.unit_code = ""

      this.$emit("save", {value: this.formdata})

      this.formdata.rate.unit_code = temp_ctc
      this.formdata.rate.title = temp_ttl
    },
  },
}
</script>

<template>
  <div class="mt-editor-form">
    <v-form
      class="mt-order-fixed-block"
      ref="form"
      v-model="valid"
      @submit.prevent="validateTabs"
    >

      <div class="mt-order-close-wdw">
        <div class="mt-editor-form-title">Оформление заказа</div>
        <div class="mt-editor-form-actions">

          <v-btn
            color="var(--main-orange)"
            rounded
            height="38"
            elevation="0"
            class="mt-editor-form-btn mt-editor-form-btn-save"
            type="submit"
          >
            Сохранить
          </v-btn>

          <v-btn
            color="var(--main-bg-color)"
            rounded
            outlined
            height="38"
            elevation="0"
            class="mt-editor-form-btn mt-editor-form-btn-close"
            @click="$emit('close', 0)"
          >
            Закрыть
          </v-btn>
        </div>
      </div>

      <div class="mt-editor-form-container">
        <v-tabs
          class="mt-editor-form-tabs"
          background-color="transparent"
          color="var(--bg-dark)"
        >
          <v-tab
            :class="tab_valid_error && !tabs_valid.main ? 'mt-editor-form-tab-error' : ''"
            @click="tabs_valid.main = true"
          >
            Основное
          </v-tab>

          <v-tab
            :class="tab_valid_error && (!tabs_checked.recipient || !tabs_valid.recipient) ? 'mt-editor-form-tab-error' : ''"
            @click="tabs_checked.recipient = true, tabs_valid.recipient = true"
          >Получатель
          </v-tab>

          <v-tab
            :class="tab_valid_error && (!tabs_checked.transmitter || !tabs_valid.transmitter) ? 'mt-editor-form-tab-error' : ''"
            @click="tabs_checked.transmitter = true, tabs_valid.transmitter = true, getShippersData()"
          >Отправители
          </v-tab>

          <v-tab
            :class="tab_valid_error && (!tabs_checked.goods || !tabs_valid.goods) ? 'mt-editor-form-tab-error' : ''"
            @click="tabs_checked.goods = true, tabs_valid.goods = true, getGoodsData()"
          >
            Товар
          </v-tab>

          <v-tab>Файлы</v-tab>

          <v-tab
            @click="getWarehouseData()"
          >
            Автодоставка
          </v-tab>

          <v-tab
            v-if="formdata.is_exw === true"
            :class="tab_valid_error && (!tabs_checked.exw || !tabs_valid.exw) ? 'mt-editor-form-tab-error' : ''"
            @click="tabs_checked.exw = true, tabs_valid.exw = true"
          >
            Доставка до порта выхода
          </v-tab>

          <v-tab-item>
            <MainInfoForm
              @updateField="updateField"
              @checkService="checkService"
              @checkIsExw="checkIsExw"
              @getAutoServiceInfo="formdata.delivery_to.length > 0 ? getAutoServiceInfo() : ''"
              ref="mainInfoForm"
              :rate="args"
              :security_price="security_price"
              :schedule="schedule"
              :agent_data="agentData"
            />

          </v-tab-item>

          <v-tab-item>
            <ConsigneeInfoForm :rate="args.rate" @updateField="updateField" ref="consigneeInfoForm"/>
          </v-tab-item>

          <v-tab-item>
            <OrderInvoiceCardList
              :invoice_data="formdata.invoices"
              @addInvoice="addInvoice"/>

            <Modal
              :showModal="show_add_invc_form"
            >
              <OrderInvoiceAddForm
                v-if="show_add_invc_form"
                :invoices="shippersData"
                class="invoice-form"
                @addInvoice="addInvoice"
                @close="show_add_invc_form = false"
              />
            </Modal>

            <div class="btn-container">
              <v-btn
                class="btn-container__add-button"
                color="var(--main-orange)"
                dark
                elevation="3"
                @click="show_add_invc_form = true"
              >
                Добавить инвойс
                <v-icon
                  dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item>

            <div class="cv-grid-div mt-4 mb-3">

              <div
                class="cv-row-div mt-0 mb-0"
                v-if="goodsData.length > 0 && (addGdsNameInput == false || addGdsNameInput == 'add')"
              >
                <v-autocomplete
                  style=" z-index: 1010"
                  v-model="formdata.gds_name"
                  :items="goodsData"
                  :label="elements.gds_name.caption"
                  item-text="gds_name"
                  return-object
                  menu-props="auto"
                  outlined
                  clearable
                  item-color="var(--main-orange)"
                  color="var(--main-orange)"
                  @change="fillGoodsByID"
                />

                <v-btn
                  text
                  x-small
                  class="drop-down-btn"
                  elevation="0"
                  outlined
                  height="56px"
                  @click="addGdsNameInput = 'add', clearGoods()"
                >
                  <v-icon x-small class="mt-1">mdi-plus</v-icon>
                  добавить
                  <br/>
                  новый товар
                </v-btn>

                <div
                  v-if="addGdsNameInput === 'add'"
                  class="input-add-card"
                >
                  <InputAddCard
                    :element="elements.gds_name"
                    @close="addGdsNameInput = false"
                    @add="formdata.gds_name = $event, addGdsNameInput = true"
                  >
                    <CVTBTextInput
                      @input="[formdata.gds_gross_weight = $event, formdata.delivery_to.length > 0 ? getAutoServiceInfo() : '']"
                      class="cvtb-edit-width"
                      dark
                      :element="elements.gds_gross_weight"
                      :val="formdata.gds_gross_weight"
                    />

                    <CVTBTextInput
                      @input="formdata.gds_volume = $event"
                      class="cvtb-edit-width"
                      :element="elements.gds_volume"
                      :val="formdata.gds_volume"
                    />
                  </InputAddCard>
                </div>
              </div>

              <div
                class="cv-row-div"
                v-else
              >
                <CVTBTextInput
                  @input="formdata.gds_name = $event"
                  class="cvtb-edit-width"
                  dark
                  :element="elements.gds_name"
                  :val="formdata.gds_name"
                />
                <v-btn
                  v-if="goodsData.length > 0"
                  text
                  height="56px"
                  class="drop-down-btn"
                  elevation="0"
                  outlined
                  @click="addGdsNameInput = false"
                >
                  выбрать из
                  <br/>
                  списка
                </v-btn>
              </div>

              <CVTBTextInput
                @input="[formdata.gds_gross_weight = $event, formdata.delivery_to.length > 0 ? getAutoServiceInfo() : '']"
                class="cvtb-edit-width"
                dark
                :element="elements.gds_gross_weight"
                :val="formdata.gds_gross_weight"
              />

            </div>

            <div class="cv-grid-div">

              <CVTBTextInput
                @input="formdata.gds_volume = $event"
                class="cvtb-edit-width"
                :element="elements.gds_volume"
                :val="formdata.gds_volume"
              />

              <CVTBDateInput
                @selected="OnEditChange"
                class="cvtb-edit-width"
                @input="formdata.ready_date = $event"
                :element="elements.ready_date"
                :val="formdata.ready_date"
              />

            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="mt-4">
              <FtpUploader
                class="ftp-uploader"
                :order_key="formdata.order_key"
                :element="elements.order_file"
                @fileUploaded="fileUploaded"
              />
            </div>
          </v-tab-item>

          <v-tab-item>

            <CVTBCheckboxInput
              :element="elements.is_car"
              @selected="OnCheckBoxChange"
              :val="formdata.is_car"
            />

            <div class="cv-grid-div mt-4 mb-16" v-if="formdata.is_car === true">
              <v-autocomplete
                v-if="profileData.is_vedexx_client == 1"
                v-model="formdata.delivery_to"
                :label="elements.delivery_to.caption"
                :loading="cityLoadingTo"
                :items="cities_to"
                :error-messages="elements.delivery_to.error_message"
                :error="elements.delivery_to.error"
                :search-input.sync="searchCityTo"
                auto-select-first
                :disabled="deliveryAccess == 0 ? true : false"
                color="var(--main-orange)"
                style="width: 100%; z-index: 1010"
                full-width
                flat
                hide-no-data
                outlined
                :messages="deliveryAccess == 0 ? 'Расчёт автодоставки временно недоступен' : ''"
                @change="getRoute"
              ></v-autocomplete>

              <div
                class="cv-row-div mt-0 mb-0"
                v-if="warehousData.length > 0 && (addWhsAddressInput == false || addWhsAddressInput == 'add')"
              >
                <v-autocomplete
                  v-model="formdata.warehouse_address"
                  :items="warehousData"
                  style=" z-index: 1010"
                  :label="elements.warehouse_address.caption"
                  return-object
                  item-text="whs_addr"
                  menu-props="auto"
                  outlined
                  clearable
                  item-color="var(--main-orange)"
                  color="var(--main-orange)"
                  @change="fillWarehouse"
                >
                </v-autocomplete>

                <v-btn
                  text
                  x-small
                  class="drop-down-btn"
                  elevation="0"
                  outlined
                  height="56px"
                  @click="addWhsAddressInput = 'add', clearWarehouse()"
                >
                  <v-icon x-small class="mt-1">mdi-plus</v-icon>
                  добавить
                  <br/>
                  новый адрес
                </v-btn>

                <div
                  v-if="addWhsAddressInput === 'add'"
                  class="input-add-card"
                >
                  <InputAddCard
                    :element="elements.warehouse_address"
                    @close="addWhsAddressInput = false"
                    @add="formdata.warehouse_address = $event, addWhsAddressInput = true"
                  >
                    <CVTBTextInput
                      @input="formdata.warehouse_name = $event"
                      :element="elements.warehouse_name"
                      :val="formdata.warehouse_name"
                    />

                    <CVTBTextInput
                      @input="formdata.warehouse_contact = $event"
                      :element="elements.warehouse_contact"
                      :val="formdata.warehouse_contact"
                    />

                    <CVTBTextInput
                      @input="formdata.warehouse_phone = $event"
                      :element="elements.warehouse_phone"
                      :val="formdata.warehouse_phone"
                    />
                  </InputAddCard>
                </div>

              </div>

              <div
                class="cv-row-div"
                v-else
              >
                <CVTBTextInput
                  @input="formdata.warehouse_address = $event"
                  :element="elements.warehouse_address"
                  :val="formdata.warehouse_address"
                />
                <v-btn
                  v-if="warehousData.length > 0"
                  text
                  height="56px"
                  class="drop-down-btn"
                  elevation="0"
                  outlined
                  @click="addWhsAddressInput = false"
                >
                  выбрать из
                  <br/>
                  списка
                </v-btn>
              </div>

              <CVTBTextInput
                @input="formdata.warehouse_name = $event"
                :element="elements.warehouse_name"
                :val="formdata.warehouse_name"
              />

              <CVTBTextInput
                @input="formdata.warehouse_contact = $event"
                :element="elements.warehouse_contact"
                :val="formdata.warehouse_contact"
              />

              <CVTBTextInput
                @input="formdata.warehouse_phone = $event"
                :element="elements.warehouse_phone"
                :val="formdata.warehouse_phone"
              />
            </div>

          </v-tab-item>

          <v-tab-item v-if="formdata.is_exw === true">
            <div class="cv-grid-div mt-4 mb-16">
              <CVTBTextInput
                @input="formdata.pickup_address = $event"
                @change="changeIncoterms"
                :element="elements.pickup_address"
                :val="formdata.pickup_address"
              />
            </div>
          </v-tab-item>

        </v-tabs>

        <div v-if="isVedexxClient === 1 && formdata.rate" class="mt-editor-form-divider"></div>

        <keep-alive>
          <PriceView
            v-if="isVedexxClient === 1 && formdata.rate"
            class="mt-editor-form-price-view"
            :rate="formdata"
            :show-loader="showServiceLoader"
          />
        </keep-alive>
      </div>
    </v-form>

    <v-dialog v-model="showAlarmGoodsForm" width="60%" persistent>
      <v-card v-if="showAlarmGoodsForm">
        <AlarmGoodsForm @close="showAlarmGoodsForm = false"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.mt-editor-form {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 2%;
  box-shadow: 0vw 0.15625vw 0.05208vw -0.1041666vw rgba(96, 97, 99, 0.2),
  0vw 0.1041666vw 0.1041666vw 0vw rgba(96, 97, 99, 0.14),
  0vw 0.05208vw 5px 0vw rgba(96, 97, 99, 0.12);
  overflow: hidden;
}

.mt-editor-form__select {
  width: 49%;
}

.mt-order-close-wdw {
  display: flex;
  min-height: 2.5vw;
  justify-content: space-between;
}

.input-add-card {
  position: absolute;
  top: 57px;
  left: 0px;
  width: 80%;
  z-index: 500;
}

.mt-editor-form-btn {
  text-transform: none;
  letter-spacing: 0.0239286em;
  height: 50px;
  margin: 0 0 0 10px;
  font-weight: 600;
  color: #FFFFFF;
  //font-size: 14px;
  //line-height: 20px;
}

.mt-editor-form-tab-error {
  color: var(--error) !important;
  background-color: #ff525229;
  //border: 2px solid var(--error);
  border-radius: 3px;
}

.mt-order-fixed-block {
  width: 98%;
  margin: auto;
  min-height: 33.8541vw;
}


.mt-editor-form-tabs {
  width: 66%;
}

.cvtb-edit-width {
  width: 100%;
}

.mt-editor-form-container {
  display: flex;
  align-content: space-between;
}

.mt-editor-form-divider {
  width: 0.5px;
  background: #a8a8a8;
  height: auto;
  min-height: 450px;
  position: relative;
  margin: 65px 0 240px 30px;
}

.mt-editor-form-price-view {
  width: 33%;
  height: auto;
  min-height: 600px;
  margin: 10px;
  position: relative;
}

.memo-width {
  height: 70vh;
  min-height: 70vh;
}

.mt-editor-form-title {
  align-items: stretch;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  width: 50%;
  margin: 10px;
}

.cv-row-div {
  display: flex;
  margin: 0;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.cv-grid-div {
  display: grid;
  z-index: 400;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  align-content: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  justify-items: flex-start;

  &:last-child {
    padding-bottom: 235px;
  }

  @media (max-width: 620px) {
    flex-direction: column;
  }
}

.cv-row-margin {
  top: -20px;
  margin: -30px auto -17px;
  justify-content: space-between;

  @media (max-width: 620px) {
    justify-content: start;
  }
}

.drop-down-btn {
  text-transform: none;
  font-size: 12px;
  float: right;
  font-weight: 600;
  height: 100%;
  color: white;
  background: var(--main-orange);
  letter-spacing: 0.0192857em;
}

.exchange-title {
  font-size: 18px;
  font-weight: 600;
}

.exchange-checkbox {
  margin-top: -10px;
}

.mt-editor-form-actions {
  text-align: right;
  margin: 0.4%;
}

.invoice-form {
  width: 65%;
}

.btn-container {
  position: relative;
  display: flex;

  &__add-button {
    margin: 20px auto;
    font-weight: 800;
  }
}

.ftp-uploader {
  width: 80%;
  position: relative;
}

.request-form__alert-link {
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

a {
  color: white !important;
  text-decoration: none;
  line-height: 10px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    //color: var(--main-bg-color);
  }
}
</style>